// CustomSelect.tsx
import React from 'react';
import { Box, TextField, Grid, useTheme, InputAdornment, IconButton } from '@mui/material';
import { tokens } from '@/utilities';
import { CustomSelectProps, OptionSelect } from '../comparativeAnalytics.model';
import CloseIcon from '@mui/icons-material/Close';
import './customSelect.styles.scss';

const CustomSelect: React.FC<CustomSelectProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  isDropdownVisible,
  options,
  onSelect,
  disabled,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dropdownVisible, setDropdownVisible] = React.useState(isDropdownVisible);

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const handleBlur = () => {
    setTimeout(() => setDropdownVisible(false), 150); 
  };

  const handleClear = () => {
    onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    setDropdownVisible(false); // Cierra el dropdown al limpiar
  };

  return (
    <Grid item xs={12} sm={10} 
      className="custom-select-container">
      <Box>
        <TextField
          required
          id={id}
          name={name}
          label={label}
          fullWidth
          size="small"
          autoComplete="off"
          variant="outlined"
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          sx={{ color: colors.grey[100], position: "relative" }}
          InputLabelProps={{
            style: {
              color: colors.grey[100],
              fontSize: '16px',
              backgroundColor: colors.background.primary,
            },
          }}
          InputProps={{
            style: { color: colors.grey[100], fontSize: '16px' },
            endAdornment: value && (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} edge="end" disabled = {disabled ?? false} >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled = {disabled ?? false}
        />

        <Box
          className={`custom-select ${dropdownVisible ? 'visible' : ''}`}
          sx={{
            color: colors.primary[600],
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
          {options.length > 0 && (
            <ul>
              {options.map((option: OptionSelect) => (
                <li
                  key={option.code || option.value}
                  onClick={() => onSelect(option, name)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default CustomSelect;
