import { Header } from "@/views";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import CustomSelect from "./components/customSelect.component";
import { ComparativeAnalyticsProps } from "./comparativeAnalytics.model";
import { processData } from "./comparativeAnalytics.hooks";
import { tokens } from "@/utilities";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react"; 

const adminValues = {
  "Administrador Facultad": 1,
  "Administrador IES": 2,
  "Super Administrador": 3,
};

type AdminRole = keyof typeof adminValues;

const ComparativeAnalyticsComponent = (props: ComparativeAnalyticsProps) => {
  const {
    userType,
    searchTermIES,
    optionsIES,
    handleInputChange,
    handleSelectChange,
    isDropdownIESVisible,
    selectedIES,
    searchTermFaculty,
    optionsFaculties,
    selectedFaculty,
    isDropdownFacultiesVisible,
    optionsCareers,
    searchTermCareer,
    isDropdownCareerVisible,
    selectedCareer,
    comparationData,
    getComparationData,
    isLoading,
    dataCareer,
  } = props;

  const userAdminValue = adminValues[userType as AdminRole];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  
  // const dataForChart = processedData && processedData.reverse();
  const [processedData, setProcessedData] = useState<any[]>([]);

  const dataForTable = processedData?.map((item: any, idx: number) => ({
      ...item,
      formattedName: `${idx + 1}. ${item.institute_name} - ${item.career_name}`,
    })).slice(0, 6) || [];

  const [title, setTitle] = useState<string>("");

  const handleGenerateReport = async () => {
    setSelectedDays(selectedValueDays);
    if (dataCareer) {
      setTitle(`Resultados para ${dataCareer.label}`);
      await getComparationData(selectedValueDays); // Llama a la función para obtener los datos
    }
  };

  useEffect(() => {
    if (comparationData) {
      const newProcessedData = processData(comparationData);
      setProcessedData(newProcessedData);
    } else {
      setProcessedData([]);
    }
  }, [comparationData]);


  const [selectedDays, setSelectedDays] = useState<string>("30") // Valor por defecto

  const [selectedValueDays, setSelectedValueDays] = useState<string>("30"); // Valor por defecto
  const handleChangeDays = (event: any) => {
    setSelectedValueDays(event.target.value);
    console.log("Nuevo valor selectedValueDays:", event.target.value);
  };
  const optionsDays = [30, 60, 90, 180, 365]; // Array de números para los días

  return (
    <Box sx={{ m: "20px", maxWidth: { xs: "100%" } }}>
      <Header
        title="Analítica comparativa"
        subtitle="Selecciona la Carrera y los días a generar el reporte."
      />
       <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: { sx: "100%" },
        }}
      >
        {/* Botón de regresar */}
        <Button
          variant="contained"
          sx={{
            display: "flex",
            gap: "10px",
            borderRadius: "5px",
            fontWeight: "regular",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
          Volver a Dashboard
        </Button>
      </Box>

      {/* Selects dinámicos */}
      {userAdminValue >= 1 && (
        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            flexDirection: "column",
            gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
            borderRadius: 2,
            width: "100%",
            gap: "20px 50px",
            marginTop: "30px",
          }}
        >
          {/* Select de Institución */}
          {userAdminValue >= 3 && (
            <CustomSelect
              id="institution"
              name="ies"
              label="Buscar y seleccionar institución"
              value={searchTermIES}
              onChange={(e) => handleInputChange(e, "ies")}
              isDropdownVisible={isDropdownIESVisible}
              options={optionsIES}
              onSelect={(option) => handleSelectChange(option, "ies")}
              disabled = {isLoading}
            />
          )}
          {/* Select de Facultad */}
          {(userAdminValue >= 2 || selectedIES) && (
            <CustomSelect
              id="faculty"
              name="faculty"
              label="Buscar y seleccionar facultad"
              value={searchTermFaculty}
              onChange={(e) => handleInputChange(e, "faculty")}
              isDropdownVisible={isDropdownFacultiesVisible}
              options={optionsFaculties}
              onSelect={(option) => handleSelectChange(option, "faculty")}
              disabled = {isLoading}
            />
          )}
          {/* Select de Institución */}
          {(userAdminValue >= 1 || selectedFaculty) && (
            <CustomSelect
              id="career"
              name="career"
              label="Buscar y seleccionar carrera"
              value={searchTermCareer}
              onChange={(e) => handleInputChange(e, "career")}
              isDropdownVisible={isDropdownCareerVisible}
              options={optionsCareers}
              onSelect={(option) => handleSelectChange(option, "career")}
              disabled = {isLoading}
            />
          )}
        </Box>
      )}

    <Box
      alignItems ="center"
      marginTop ="20px"
      marginBottom ="30px"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "start" },
        }}
    >    
        {/*Select de Días a Generar reprote*/}
        <Box 
          sx={{
              display: "flex",
              textAlign: "left",
              alignContent: "middle",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "start" },
              width: { xs: "fit-content", md: "100%" },
              height: "100%"
            }}
          >
          <Typography variant="subtitle2"  
            sx={{
              // textAlign: "center",
              textAlign: "left", // Alineación a la izquierda
              marginBottom: "8px", // Espacio debajo del texto
              color: colors.grey[100],              
              // width: { xs: "fit-content", md: "100%" },
              width:  "fit-content" ,
              height: "100%",
              textTransform: "none",
              alignContent: "middle",
              }}> Seleccione los días:
          </Typography>
          <Select
            sx={{
              // // backgroundColor: "#3F51B5",
              // color: colors.grey[100],
              // fontSize: "14px",
              // borderRadius: "5px",
              // width: "fit-content",
              // height: "fit-content",
              // textTransform: "none",
              // marginLeft: 1,
              color: colors.grey[100],
              fontSize: "14px",
              borderRadius: "5px",
              width: "fit-content",
              height: "fit-content",
              textTransform: "none",
              marginLeft: { xs: 0, md: 1 }, // Quita el margen en pantallas pequeñas
            }}
            labelId="select-dias-label"
            value={selectedValueDays}
            onChange={handleChangeDays}
            label=""
            disabled={!selectedCareer || isLoading}
          >
            {optionsDays.map((days) => (
              <MenuItem
                key={days}
                value={days}
                sx={{ color: colors.grey[100], fontSize: '16px'  }}
                >
                {days} días
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* Botón de generar reporte */}
        <Box
          marginLeft="20px"
          // marginRight= {{ xs: "none", md: "auto" }}
          alignItems="center"
          sx={{
            display: "flex",
            alignItems: "center",
            width:   "100%",
            flexDirection: { xs: "column", md: "row" },
          }}
          >
          <Button
            sx={{
              marginTop: { xs: 3, md: 0 },
              // marginRight: "auto",
              backgroundColor: "#3F51B5",
              color: "#f2f0f0",
              fontSize: "14px",
              borderRadius: "5px",
              width: { xs: "fit-content" },
              height: "fit-content",
              textTransform: "none",
              "&:hover": {
                backgroundColor: colors.blueAccent[500],
                cursor: "pointer",
              },
              "&.Mui-disabled": {
                backgroundColor: "#E0E0E0",
                color: "#9E9E9E",
                cursor: "not-allowed",
              },
            }}
            disabled={!selectedCareer || isLoading}
            onClick={handleGenerateReport}
          >
            Generar reporte
          </Button>
        </Box>
      </Box>

      {/* Título dinámico */}
      <Typography
        variant="h4"
        sx={{
          color: colors.grey[100],
          marginTop: "20px",
          marginBottom: "2px",
        }}
      >
        {title}
      </Typography>
      { title.length > 0 ? (
          <Typography variant="subtitle2"
              sx={{
                display: { color: colors.grey[100], fontStyle: "italic" },
                alignItems: "center",
              }}
            >
              Nota: La informacion presentada es de los últimos {selectedDays} días. 
          </Typography> 
      ) : null}
      {/* Tabla de resultados */}
      {dataCareer   ? (
        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            flexDirection: "column",
            gridTemplateColumns: "repeat(2, minmax(350px, 1fr))",
            borderRadius: 2,
            width: "100%",
            gap: "20px 50px",
            marginTop: "5px",
            marginBottom: "40px",
          }}
        >
          {isLoading ? (
             <CircularProgress
                sx={{
                  color: theme.palette.mode === "dark" ? "#ffffff" : "#3F51B5",
                }}
              />
          ) : dataForTable && dataForTable.length > 0  ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Carrera</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>No. de veces</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataForTable.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{item.formattedName}</TableCell>
                      <TableCell align="right">{item.total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : comparationData && (
            <Typography variant="h5" color={colors.grey[500]}>
              No encontramos coincidencias de comparaciones
            </Typography>
          )}
        </Box>
        ) : null}
    </Box>
  );
};

export default ComparativeAnalyticsComponent;
